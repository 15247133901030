
    import { mapMutations } from "vuex";

    export default {
        name: 'Modal-resume-menu',
        data() {
            return {
                show: false,
            }
        },
        computed: {
            currentPage() {
                return this.$route.path;
            },
            showMenuModal () {
                return this.$store.state.showMenuModal
            }
        },
        methods: {
            ...mapMutations([
                'toggleMenuModal',
                'closeMenuModal'
            ]),
            closeDropdown: function() {
                this.show = false;
                this.closeMenuModal();
            },
            toggleDropdown: function() {
                this.show = ! this.show;
            },
            closeOnSameRoute: function() {
                if ( this.currentPage === '/' ) {
                    this.closeMenuModal();
                }
            }
        }
    }
