import Echo from "laravel-echo";

window.io = require("socket.io-client");

export default ({app, store}) => {
    console.log()
  window.Echo = new Echo({
    broadcaster: "socket.io",
    host: app.$config.coreEcho,
    authEndpoint: `${app.$config.coreEcho}/broadcasting/tenant`,
    auth: {
      headers: {
        Authorization: `Bearer ${store.state.authData.accessToken}`,
        "X-Client": store.state.initializedId,
      },
    },
  });
};
