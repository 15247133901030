
    import { Fragment } from 'vue-fragment';

    import MenuModal from '~/components/Modal/MenuModal';
    import SearchModal from '~/components/Modal/SearchModal';

    export default {
        name: 'Footer',
        components: {
            Fragment,
            MenuModal,
            SearchModal,
        }
    }
