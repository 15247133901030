
    export default {
        name: 'PageTitle',
        props: {
          title: {
            type: String,
            default: "Demo Title"
          }
        }
    }
