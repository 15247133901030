import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29af9f5d = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _012845dc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _603e0073 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _20f9eb2b = () => interopDefault(import('../pages/blog-single-post/index.vue' /* webpackChunkName: "pages/blog-single-post/index" */))
const _be6e7b8e = () => interopDefault(import('../pages/follow-program/index.vue' /* webpackChunkName: "pages/follow-program/index" */))
const _77621132 = () => interopDefault(import('../pages/portfolio-inside/index.vue' /* webpackChunkName: "pages/portfolio-inside/index" */))
const _2ab9c0ad = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _04a01c00 = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _e4c6942c = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _edb5193c = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _7cab67cc = () => interopDefault(import('../pages/sign-in/index.vue' /* webpackChunkName: "pages/sign-in/index" */))
const _47c77210 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _5c79a328 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _1904ee65 = () => interopDefault(import('../pages/ui/index.vue' /* webpackChunkName: "pages/ui/index" */))
const _2c0be4a8 = () => interopDefault(import('../pages/verification/index.vue' /* webpackChunkName: "pages/verification/index" */))
const _5c6b4c74 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6f896d5e = () => interopDefault(import('../pages/program/_id.vue' /* webpackChunkName: "pages/program/_id" */))
const _4f368964 = () => interopDefault(import('../pages/programs/_category/index.vue' /* webpackChunkName: "pages/programs/_category/index" */))
const _d8eb12e2 = () => interopDefault(import('../pages/programs/_category/_tag.vue' /* webpackChunkName: "pages/programs/_category/_tag" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _29af9f5d,
    name: "404"
  }, {
    path: "/account",
    component: _012845dc,
    name: "account"
  }, {
    path: "/blog",
    component: _603e0073,
    name: "blog"
  }, {
    path: "/blog-single-post",
    component: _20f9eb2b,
    name: "blog-single-post"
  }, {
    path: "/follow-program",
    component: _be6e7b8e,
    name: "follow-program"
  }, {
    path: "/portfolio-inside",
    component: _77621132,
    name: "portfolio-inside"
  }, {
    path: "/privacy",
    component: _2ab9c0ad,
    name: "privacy"
  }, {
    path: "/programs",
    component: _04a01c00,
    name: "programs"
  }, {
    path: "/reset-password",
    component: _e4c6942c,
    name: "reset-password"
  }, {
    path: "/search-results",
    component: _edb5193c,
    name: "search-results"
  }, {
    path: "/sign-in",
    component: _7cab67cc,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _47c77210,
    name: "sign-up"
  }, {
    path: "/terms",
    component: _5c79a328,
    name: "terms"
  }, {
    path: "/ui",
    component: _1904ee65,
    name: "ui"
  }, {
    path: "/verification",
    component: _2c0be4a8,
    name: "verification"
  }, {
    path: "/",
    component: _5c6b4c74,
    name: "index"
  }, {
    path: "/program/:id?",
    component: _6f896d5e,
    name: "program-id"
  }, {
    path: "/programs/:category",
    component: _4f368964,
    name: "programs-category"
  }, {
    path: "/programs/:category/:tag",
    component: _d8eb12e2,
    name: "programs-category-tag"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
