
import api from "~/mixins/api";
    export default {
        name: 'Sidebar',
        mixins: [api],
        data() {
          return {
            tasks: []
          }
        },
        async created(){
          this.tasks = await this.get("personal-chain/tasks");
        }
    }
