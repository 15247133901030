
  import layout from "~/mixins/layout";
    export default {
        name: 'Loading',
        mixins: [layout],
        data() {
            return {
                isLoading: false,
                show: false,
            }
        },
        mounted() {
            this.show = true;

            setTimeout( () => {
                this.isLoading = false;
            }, 1000 )
        }
    }
