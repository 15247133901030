
import Loading from '~/components/Loading/Loading';
import Header from '~/components/blocks/header/Header';
import Footer from '~/components/blocks/footer/Footer';

export default {
  components: {
    Loading,
    Header,
    Footer
  },
  mounted: function() {
    document.body.classList.add( 'single' );
    document.body.classList.add( 'single-post' );
    document.body.classList.add( 'aos-true' );
    if(process.client) {
      this.$aosAnimate();
    }
  },
  beforeDestroy() {
    document.body.classList.remove( 'single' );
    document.body.classList.remove( 'single-post' );
    document.body.classList.remove( 'aos-true' );
  },
  metaInfo: {
    title: 'Blog single post | Sely - Personal Resume vCard Vue JS Template',
    titleTemplate: '%s'
  }
}
