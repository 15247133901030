
    import layout from "../../../mixins/layout";
    export default {
        name: 'HeaderLogo',
        mixins: [layout],
                computed: {
            currentPage() {
                return this.$route.path;
            },
            showMenuModal () {
                return this.$store.state.showMenuModal
            }
        },
        methods: {
            closeModal: function() {
                if (this.currentPage === '/') {
                    if (this.showMenuModal) {
                        this.$store.commit( 'closeMenuModal' );
                    }
                } else {
                    return;
                }
            }
        }
    }
