
    export default {
        name: 'Resume-menu',
        data() {
            return {
                show: false,
            }
        },
        computed: {
            currentPage() {
                return this.$route.path;
            }
        },
      methods: {
            handeleMouseEnter: function() {
                this.show = true;
            },
            scrollToSection(sectionId){

            }
        }
    }
