import { render, staticRenderFns } from "./account.vue?vue&type=template&id=60ec76b6"
import script from "./account.vue?vue&type=script&lang=js"
export * from "./account.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/tmp/build_c93d3ae2/components/Loading/Loading.vue').default})
