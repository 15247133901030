import Vue from 'vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.use(AOS.init())
export default ({ app, store }, inject) => {
    inject('aosAnimate', ()=>{
        let ua        = navigator.userAgent.toLowerCase();
        let isAndroid = ua.indexOf( "android" ) > -1;
        if ( document.querySelector('body').classList.contains( 'aos-true' )){
            if ( document.querySelector('.loading') ) {
                if ( ! isAndroid ) {
                    setTimeout( function() {
                        AOS.init( {
                            easing: 'ease-out-back',
                            duration: 2000,
                            offset: 60
                        } );
                    }, 1000 );
                } else {
                    AOS.init( {
                        easing: 'ease-out-back',
                        duration: 2000,
                        offset: 60
                    } );
                }
            } else {
                AOS.init( {
                    easing: 'ease-out-back',
                    duration: 2000,
                    offset: 60
                } );
            }
        }
    })
}
