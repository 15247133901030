
    import api from "~/mixins/api";
    export default {
        name: 'SignOut',
        mixins: [api],
        data() {
            return {
                show: false,
            }
        },
        computed: {
            currentPage() {
                return this.$route.path;
            }
        },
        methods: {
            async signOut() {
              this.$store.commit("signOut");
              await this.get(`user/sign-out`);
              this.$router.push("/");
            },
            closeDropdown: function() {
                this.show = false;
            },
            handeleMouseEnter: function() {
                this.show = true;
            },
        }
    }
