
    import api from "~/mixins/api";
    export  default {
        mixins: [api],
        name: 'Actions',
        data(){
          return {
            linkName: null
          }
        },
        computed: {
          userId() {
            return this.$store.state.authData.id;
          },
        },
        created(){
          this.getLinkName();
        },
        methods: {
          async getLinkName(){
            const linkData = await this.get(`public/get-container/menuSettings`);
            if(linkData){
              this.linkName = linkData.clinkName;
            }
          },
          async getClinicLink(){
            if (process.browser) {
              const protocol = process.env.NODE_ENV === "development" ? "http" : "https";
              if (this.userId) {
                const tokenData = await this.get("user/get-token");
                window.location.href = `${protocol}://clinic.${window.location.hostname}?token=${tokenData.token}`;
              } else {
                window.location.href = `${protocol}://clinic.${window.location.hostname}`;
              }
            }
          }
        }
    }
