
    import Logo from '~/components/blocks/header/HeaderLogo';
    import ResumeMenu from '~/components/blocks/General/ResumeMenu';
    import BlogMenu from '~/components/blocks/General/BlogMenu';
    import Actions from '~/components/blocks/General/Actions';
    import SearchModalButton from '~/components/Button/SearchModalButton';
    import MenuModalButton from '~/components/Button/MenuModalButton';
    import SignIn from "../General/SignIn.vue";
    import SignUp from "../General/SignUp.vue";
    import SignOut from "../General/SignOut.vue";
    export default {
        head () {
          return {
            htmlAttrs: {
              style: `--primary-color:${this.$store.state.layout.items ? this.$store.state.layout.items.primaryColor : '#ff0000'};`
            }
          }
        },
        name: 'Header',
        components: {
          SignOut,
          SignUp,
          SignIn,
            Logo,
            ResumeMenu,
            BlogMenu,
            Actions,
            SearchModalButton,
            MenuModalButton
        },

        data() {
            return {
                scrollPosition: null,
                slideInDownClass: '',
            }
        },
        methods: {
            updateScroll() {
                console.log("UPDATE SCROLL")
                this.scrollPosition = window.scrollY;
                let el              = document.querySelector( '#main' ).getBoundingClientRect();
                let fromTop         = -1;

                if ( window.pageYOffset - ( el.top + window.scrollY ) > fromTop ) {
                    document.body.classList.add( 'header-fixed' );
                    this.slideInDownClass = ' animated slideInDown';
                } else {
                    document.body.classList.remove( 'header-fixed' );
                    this.slideInDownClass = '';
                }
            },
        },
        mounted() {
            const fixedNavbarFor = ['/', '/account'];
            if (fixedNavbarFor.includes(this.currentPage)) {
                window.addEventListener( 'scroll', this.updateScroll );
            }
        },
        destroyed() {
            window.removeEventListener( 'scroll', this.updateScroll );

            if ( document.body.classList.contains( 'header-fixed' ) ) {
                document.body.classList.remove( 'header-fixed' );
            }
        },
        computed: {
            currentPage() {
                return this.$route.path;
            },
            isGuest() {
              return this.$store.state.authData.accessToken === null;
            },
        }
    }
