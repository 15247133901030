
import Loading from '~/components/Loading/Loading';
import Header from '~/components/blocks/header/Header';
import Footer from '~/components/blocks/footer/Footer';
import PageTitle from "~/components/blocks/blog-single-post/PageTitle.vue";;
import Sidebar from "~/components/account/Sidebar.vue";
import api from "@/mixins/api";

export default {
  mixins: [api],
  components: {
    Sidebar, PageTitle,
    Loading,
    Header,
    Footer
  },
  data(){
    return {
        userData: null
    }
  },
  async created(){
    this.userData = await this.get("user/get-data");
  },
  mounted: function() {
    document.body.classList.add( 'single' );
    document.body.classList.add( 'single-post' );
    document.body.classList.add( 'aos-true' );
    if(process.client) {
      this.$aosAnimate();
    }
  },
  beforeDestroy() {
    document.body.classList.remove( 'single' );
    document.body.classList.remove( 'single-post' );
    document.body.classList.remove( 'aos-true' );
  },
  metaInfo: {
    title: 'Blog single post | Sely - Personal Resume vCard Vue JS Template',
    titleTemplate: '%s'
  },
}
