
    export default {
        name: 'SearchForm',
      data() {
        return {
          urlAction: null,
        };
      },
      mounted() {
        if (process.client) {
          this.urlAction =
              window.location.origin +
              this.$router.resolve({
                name: "search-results",
              }).href;
        }
      },
    }
