
    export default {
        name: 'Blog-menu',
        data() {
            return {
                show: false,
            }
        },
        computed: {
            currentPage() {
                return this.$route.path;
            }
        },
        methods: {
            closeDropdown: function() {
                this.show = false;
            },
            handeleMouseEnter: function() {
                this.show = true;
            },
        }
    }
